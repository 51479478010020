/* src/components/Slider.css */
/* Swiper default fade effect compatibility */
.swiper-container {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.swiper-slide {
    opacity: 0 !important;
}

.swiper-slide-active {
    opacity: 1 !important;
    transition: opacity 0.6s ease;
}


.slide {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0 5rem;
}

.slide-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}


.text-content h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    max-width: 200px;
    font-weight: 700;
    text-transform: uppercase;
    line-height: 4rem;
}

.text-content p {
    font-size: 16px;
    line-height: 26px;
    max-width: 550px;
    opacity: .5;
}

.image-content img {
    max-width: 600px;
    height: auto;
    margin: 0 auto;
}


.custom-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 30px;
    margin: 0 auto;
    z-index: 3000;

}

.bullet {
    width: 30px;
    height: 5px;
    margin: 0 5px;
    background-color: #d4d4d4;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.bullet.active {
    background-color: black; /* Active bullet color */
    color: white;
}

@media (max-width: 576px) {
    .swiper-container {
        height: auto;
    }
    .slide-content {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0;
        gap: 40px;
    }
    .image-content img {
        max-width: 300px;
        height: auto;
    }
    .swiper-slide {
        width: 100% !important;
    }
    .slide {
        padding: 0 1.5rem;
    }

    .custom-pagination {
        position: relative;
    }

}


@media (min-width: 768px) {
    .slide-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .image-content img {
        max-width: 300px;
        height: auto;
    }
    .slide {
        padding: 0 2.5rem;
    }
}


@media (min-width: 992px) {
    .slide {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 0 5rem;
    }

    .slide-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: end;
        width: 100%;
        height: 100%;
    }

    .image-content img {
        max-width: 400px;
        height: auto;
    }
}


@media (min-width: 1200px) {

}


@media (min-width: 1400px) {

}
