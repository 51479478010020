.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensures popup is above everything */
}

.popup-content {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    width: 380px;
    text-align: center;
    z-index: 1000; /* Ensures content stays on top of the popup background */
    position: relative;
}

.popup-content h2 {
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 1px;
    text-align: left;
}
.popup-content form {
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
}
.popup-content form label {
    display: block;
    text-align: left;
    color: black;
    opacity: .6;
    font-size: 14px;
    font-weight: 300;
}

.popup-content form input,
.popup-content form textarea{
    width: 100%;
    margin-top: 5px;
    padding: 10px 15px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,.3);
}

.popup-content form button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    background: black;
    border-radius: 4px;
    color: white;
    padding: 10px 30px;
    cursor: pointer;
    border:1px solid black;
    transition: ease-in-out .25s;
    width: 100%;
}
.popup-content form button:hover {
    background: white;
    color: black;
    border:1px solid black;
    transition: ease-in-out .25s;
}

button {
    cursor: pointer; /* Ensures pointer is shown on hover */
}
.btn_close {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 0;
    background: black;
    color: white;
    font-weight: 700;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
}
